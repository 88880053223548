import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ArrowheadRightDarkIcon } from "../../../assets";
import {
  ProjectAccessConstants,
  ProjectActivitiesConstants,
  ProjectPermissionConstants,
  UserType,
} from "../../../constants";
import { CursorChangeProps, Origin, ResultType } from "../../../models";
import { IStateOrigin } from "../../../navigators/navigateStateObjects";
import {
  getCurrentUserProjectPermissions,
  GetCurrentUserProjectPermissionsResponse,
  getProjectAggregate,
  searchActivities,
  SearchActivitiesResponse,
  SearchActivityHistoryResponse,
  SearchProjectOrganisationInvitations,
  searchProjects,
  SearchProjectsResponse,
} from "../../../service/query";
import { ResultData, Status } from "../../../service/Shared";
// import { getAggregate } from "../../../service/stats";  // TODO ENG-557 hidden until post-launch
import { useAuth } from "../../../useAuth";
import { flattenObject, hasActivityPermissionForProject } from "../../../utils";
import {
  getActivityDashboardTabRoute,
  getActivityViewRoute,
  getProjectDetailsByUuid,
  getProjectManageProjectGroupRoute,
  getSettingsProjectAccessRoute,
} from "../../../utils/routes";
import {
  ChartData,
  DataGridButtonLinkCellFormatterData,
  DataGridColumnDefinition,
  DataGridCursorDataLoadEventHandler,
  DataGridLinkCellFormatterData,
  dataGridMapFilterCriteria,
  ProfileMenuItemType,
  Toast,
} from "../../../widget";
import { IconCellFormatterData } from "../../../widget/data/DataGrid/models";
import { resumeActivity } from "../../shared/utils";

interface UseDashboardReturnData {
  developerHasReceivedProjectInvitations: boolean;
  navigateToProjectInvitations: () => void;
  statusChartData: ChartData;
  unitsChartData: ChartData;
  metricsChartData: ChartData;
  tableColumnsTopProjects: DataGridColumnDefinition[];
  tableColumnsInProgressActivities: DataGridColumnDefinition[];
  tableColumnsInReviewActivities: DataGridColumnDefinition[];
  isLoading: boolean;
  isLoadingProjects: boolean;
  isLoadingActivities: boolean;
  onTopProjectsDataLoad: DataGridCursorDataLoadEventHandler;
  onRecentActivitiesInProgressDataLoad: DataGridCursorDataLoadEventHandler;
  onRecentActivitiesChangesRequiredDataLoad: DataGridCursorDataLoadEventHandler;
  quickActionsMenuOptionsList: ProfileMenuItemType[];
  showProfileMenu: boolean;
  showCreateProjectModal: boolean;
  setShowProfileMenu: Dispatch<SetStateAction<boolean>>;
  setShowCreateProjectModal: Dispatch<SetStateAction<boolean>>;
  setShowVersionConflictModal: Dispatch<SetStateAction<boolean>>;
  showVersionConflictModal: boolean;
  hasPermission: (permission: string) => boolean;
  newVersionActivityHistory?: SearchActivityHistoryResponse;
  draftActivityHistoryUuid?: string;
  currentUserType: UserType;
  currentUserRole?: string;
}

export const useDashboard = (): UseDashboardReturnData => {
  const {
    currentDeveloperUuid,
    currentVerifierUuid,
    currentStandardUuid,
    currentOrganisationUuid,
    currentUserRole,
    currentUserType,
    hasPermission,
  } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [developerHasReceivedProjectInvitations, setDeveloperHasReceivedProjectInvitations] = useState<boolean>(false);
  const [statusChartData, setStatusChartData] = useState<ChartData>({});
  const [unitsChartData, setUnitsChartData] = useState<ChartData>({});
  const [metricsChartData, setMetricsChartData] = useState<ChartData>({});
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingProjects, setIsLoadingProjects] = useState(true);
  const [isLoadingActivities, setIsLoadingActivities] = useState(true);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
  // eslint-disable-next-line unused-imports/no-unused-vars
  const [newVersionActivityHistory, setNewVersionActivityHistory] = useState<SearchActivityHistoryResponse>();
  const [showVersionConflictModal, setShowVersionConflictModal] = useState(false);
  const [draftActivityHistoryUuid, setDraftActivityHistoryUuid] = useState<string>();

  const tableColumnsTopProjects: DataGridColumnDefinition[] = [
    {
      key: "link",
      name: "Project",
      dataType: "string",
      formatter: "link",
    },
    { key: "standard.displayName", name: "Code", dataType: "string" },
    { key: "developer.displayName", name: "Developer", dataType: "string" },
    {
      name: "Status",
      key: "status",
      dataType: "string",
      formatter: "projectStatusPill",
      minWidth: 200,
    },
    {
      key: "cachedPiuQuantity",
      name: "PIUs",
      dataType: "string",
      formatter: "align",
      alignment: "right",
    },
    {
      key: "cachedVcuQuantity",
      name: "VCUs",
      dataType: "string",
      formatter: "align",
      alignment: "right",
    },
  ];

  const tableColumnsInProgressActivities: DataGridColumnDefinition[] = [
    {
      key: "projectLink",
      name: "Project / Group",
      dataType: "string",
      formatter: "link",
    },
    { key: "standardDisplayName", name: "Code", dataType: "string" },
    {
      key: "activityLink",
      name: "Activity",
      dataType: "string",
      formatter: "buttonLink",
    },
    {
      name: "Status",
      key: "status",
      dataType: "string",
      formatter: "projectActivitiesStatusPill",
      minWidth: 240,
    },
    {
      name: "Progress",
      key: "latestVersion.completionPercentage",
      dataType: "number",
      formatter: "progress",
    },
    {
      name: "Last updated",
      key: "latestVersion.updatedAt",
      dataType: "Date",
      formatter: "dateOnly",
      filterable: false,
    },
    {
      key: "activityDetailLink",
      name: "More details",
      dataType: "string",
      formatter: "icon",
      alignment: "center",
      filterable: false,
      sortable: false,
    },
  ];

  const tableColumnsInReviewActivities: DataGridColumnDefinition[] = [
    {
      key: "projectLink",
      name: "Project / Group",
      dataType: "string",
      formatter: "link",
    },
    { key: "standardDisplayName", name: "Code", dataType: "string" },
    {
      key: "activityLink",
      name: "Activity",
      dataType: "string",
      formatter: "buttonLink",
    },
    {
      name: "Status",
      key: "status",
      dataType: "string",
      formatter: "projectActivitiesStatusPill",
      minWidth: 240,
    },
    {
      name: "Verifier",
      key: "verifierDisplayName",
      dataType: "string",
    },
    {
      name: "Submission date",
      key: "firstSubmissionDate",
      dataType: "Date",
      formatter: "dateOnly",
      filterable: false,
    },
    {
      name: "Last updated",
      key: "reviewVersion.createdAt",
      dataType: "Date",
      formatter: "dateOnly",
      filterable: false,
    },
    {
      key: "activityDetailLink",
      name: "More details",
      dataType: "string",
      formatter: "icon",
      alignment: "center",
      filterable: false,
      sortable: false,
    },
  ];

  const quickActionsMenuOptionsList: ProfileMenuItemType[] = [
    {
      id: 1,
      value: "New project",
    },
  ];

  const { filterBy, filterValue } = useMemo(() => {
    if (currentDeveloperUuid) {
      return {
        filterBy: "developer",
        filterValue: currentDeveloperUuid,
      };
    }
    if (currentVerifierUuid) {
      return {
        filterBy: "verifier",
        filterValue: currentVerifierUuid,
      };
    }
    if (currentStandardUuid) {
      return {
        filterBy: "standard",
        filterValue: currentStandardUuid,
      };
    }
    throw new Error("Organisation not currently linked to a developer, verifier, standard");
  }, [currentDeveloperUuid, currentVerifierUuid, currentStandardUuid]);

  const fetchData = useCallback(async () => {
    const [statusAggregateResponse, piuUnitsAggregateResponse, vcuUnitsAggregateResponse, metricsAggregateResponse] =
      await Promise.all([
        getProjectAggregate({
          filterBy,
          filterOperator: "eq",
          filterValue,
          groupBy: "status",
          aggregation: "count",
          aggregate: "id",
        }),
        getProjectAggregate({
          filterBy,
          filterOperator: "eq",
          filterValue,
          aggregation: "sum",
          aggregate: "cachedPiuQuantity",
          groupBy: null,
        }),
        getProjectAggregate({
          filterBy,
          filterOperator: "eq",
          filterValue,
          aggregation: "sum",
          aggregate: "cachedVcuQuantity",
          groupBy: null,
        }),
        // TODO ENG-557 hidden until post-launch
        Promise.resolve({
          status: Status.Error,
          data: [{ key: "remove", objectName: "remove", value: 1 }],
        }),
        // getAggregate({
        //   periodicity: "AllTime",
        //   metric: "PageViews",
        //   objectType: "project",
        //   objectUuid: null,
        //   timestamp: new Date(),
        // }),
      ]);

    if (statusAggregateResponse.status === Status.Success && statusAggregateResponse.data) {
      setStatusChartData(
        statusAggregateResponse.data.reduce<ChartData>((previous, current) => {
          // eslint-disable-next-line no-param-reassign
          previous[current.key] = current.value;

          return previous;
        }, {})
      );
    }

    if (
      piuUnitsAggregateResponse.status === Status.Success &&
      piuUnitsAggregateResponse.data &&
      vcuUnitsAggregateResponse.status === Status.Success &&
      vcuUnitsAggregateResponse.data
    ) {
      setUnitsChartData({
        "Verified carbon units (VCUs)": vcuUnitsAggregateResponse.data[0].value,
        "Pending issuance units (PIUs)": piuUnitsAggregateResponse.data[0].value,
      });
    }

    if (metricsAggregateResponse.status === Status.Success && metricsAggregateResponse.data) {
      setMetricsChartData(
        metricsAggregateResponse.data.reduce<ChartData>((previous, current) => {
          // eslint-disable-next-line no-param-reassign
          previous[current.key] = current.value;

          return previous;
        }, {})
      );
    }
  }, []);

  const fetchProjectInvitations = useCallback(async () => {
    const filterCriteria = dataGridMapFilterCriteria([]);

    filterCriteria.targetOrganisation = {
      uuid: {
        operator: "eq",
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        value: currentOrganisationUuid!,
      },
    };

    filterCriteria.calculatedStatus = {
      operator: "eq",
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      value: ProjectAccessConstants.STATUS_SENT,
    };

    const response = await SearchProjectOrganisationInvitations({
      paging: {
        afterCursor: null,
        beforeCursor: null,
        limit: 1,
      },

      filter: { results: filterCriteria },
    });

    if (response.status === Status.Success) {
      if (response.data?.results && response.data?.results.length > 0) {
        setDeveloperHasReceivedProjectInvitations(true);
      } else {
        setDeveloperHasReceivedProjectInvitations(false);
      }
    }
  }, []);

  useEffect(() => {
    fetchData().finally(() => {
      setIsLoading(false);
    });
    fetchProjectInvitations();
  }, [fetchData, fetchProjectInvitations]);

  const formatData = useCallback(
    (responseData: SearchProjectsResponse | undefined): ResultData[] =>
      responseData?.results?.map((el) => {
        const result = flattenObject(el);

        result.link = {
          text: el.displayName,
          to: getProjectDetailsByUuid(el.uuid, currentUserType),
        } as DataGridLinkCellFormatterData;

        return result;
      }) || [],
    []
  );

  const formatActivitiesData = useCallback(
    (
      responseData: SearchActivitiesResponse | undefined,
      permissions: GetCurrentUserProjectPermissionsResponse[]
    ): ResultData[] =>
      responseData?.results?.map((el) => {
        const result = flattenObject(el);

        result.projectLink = {
          text: el.project ? el.project.displayName : el.group?.displayName,
          to: el.project
            ? getProjectDetailsByUuid(el.project.uuid, currentUserType)
            : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              getProjectManageProjectGroupRoute(el.group!.uuid!, `origin=${Origin.DeveloperDashboardPage}`),
          state: { origin: { goBackText: "Back to Dashboard", from: location.pathname } as IStateOrigin },
        } as DataGridLinkCellFormatterData;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        result.standardDisplayName = el.project ? el.project.standard.displayName : el.group?.standard?.displayName;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        result.verifierDisplayName = el.project ? el.project.verifier?.displayName : el.group?.verifier?.displayName;

        if (
          (el.status === ProjectActivitiesConstants.STATUS_IN_PROGRESS ||
            el.status === ProjectActivitiesConstants.STATUS_CHANGES_REQUIRED ||
            el.status === ProjectActivitiesConstants.STATUS_REVIEW_CANCELLED) &&
          el.project &&
          hasActivityPermissionForProject(
            permissions,
            el.project.uuid,
            ProjectPermissionConstants.MANAGE_PROJECT_ACTIVITY
          )
        ) {
          result.activityLink = {
            text: el.activityDefinition.displayName,
            action: () => {
              if (el.isUnderReview && el.draftVersion) {
                navigate(
                  getActivityViewRoute(
                    el.draftVersion?.uuid,
                    currentUserType,
                    el.project ? `projectUuid=${el.project.uuid}` : ""
                  ),
                  { state: { origin: { goBackText: "Back to dashboard", from: location.pathname } as IStateOrigin } }
                );
              } else if (el.isUnderReview && el.currentVersion) {
                navigate(
                  getActivityViewRoute(
                    el.currentVersion?.uuid,
                    currentUserType,
                    el.project ? `projectUuid=${el.project.uuid}` : ""
                  ),
                  { state: { origin: { goBackText: "Back to dashboard", from: location.pathname } as IStateOrigin } }
                );
              } else {
                resumeActivity(
                  navigate,
                  setDraftActivityHistoryUuid,
                  setNewVersionActivityHistory,
                  setShowVersionConflictModal,
                  el.uuid,
                  el.draftVersion?.uuid,
                  el.draftVersion?.versionNumber
                );
              }
            },
          } as DataGridButtonLinkCellFormatterData;
        } else {
          result.activityLink = {
            text: el.activityDefinition.displayName,
            action: () => {
              if (el.currentVersion) {
                navigate(
                  getActivityViewRoute(
                    el.currentVersion?.uuid,
                    currentUserType,
                    el.project ? `projectUuid=${el.project.uuid}` : ""
                  ),
                  { state: { origin: { goBackText: "Back to dashboard", from: location.pathname } as IStateOrigin } }
                );
              } else if (el.reviewVersion) {
                navigate(
                  getActivityViewRoute(
                    el.reviewVersion.uuid,
                    currentUserType,
                    el.project ? `projectUuid=${el.project.uuid}` : ""
                  ),
                  { state: { origin: { goBackText: "Back to dashboard", from: location.pathname } as IStateOrigin } }
                );
              } else {
                Toast.error({
                  message: `This activity has not yet been published. Once ${el.createdByUser.fullName} has published the activity, you can view the activity.`,
                });
              }
            },
          } as DataGridButtonLinkCellFormatterData;
        }

        result.activityDetailLink = {
          action: () =>
            navigate(
              getActivityDashboardTabRoute(
                el.uuid,
                "documents",
                currentUserType,
                el.project ? `projectUuid=${el.project.uuid}` : ""
              ),
              { state: { origin: { goBackText: "Back to dashboard", from: location.pathname } as IStateOrigin } }
            ),
          icon: <ArrowheadRightDarkIcon width={12} height={12} />,
        } as IconCellFormatterData;

        return result;
      }) || [],
    []
  );

  const onTopProjectsDataLoad: DataGridCursorDataLoadEventHandler = async ({ paging }: CursorChangeProps) => {
    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 5,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };
    setIsLoadingProjects(true);

    await searchProjects({
      paging: {
        limit: 10,
        beforeCursor: null,
        afterCursor: null,
      },
      sort: [
        {
          key: "cachedPiuQuantity" as "results.cachedPiuQuantity",
          direction: "desc",
        },
      ],
    })
      .then((response) => {
        data = {
          resultData: formatData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            hasNextPage: false,
            hasPreviousPage: false,
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || response.data?.results?.length || 0,
          },
        };
      })
      .finally(() => {
        setIsLoadingProjects(false);
      });
    return data;
  };

  type OnCursorChangeEventHandler = (
    changeProps: CursorChangeProps,
    isInProgress: boolean,
    isInReview: boolean
  ) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;

  const onRecentActivitiesDataLoad: OnCursorChangeEventHandler = async (
    { paging }: CursorChangeProps,
    isInProgress: boolean,
    isInReview: boolean
  ) => {
    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 5,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };
    setIsLoadingActivities(true);
    const filterCriteria = dataGridMapFilterCriteria([]);
    let activityStatuses: ProjectActivitiesConstants[] = [];
    if (isInProgress) {
      activityStatuses = ProjectActivitiesConstants.INPROGESS_STATUSES;
      filterCriteria.status = {
        operator: "in",
        value: activityStatuses,
      };
      filterCriteria.isUnderReview = {
        operator: "eq",
        value: false,
      };
    } else if (isInReview) {
      activityStatuses = ProjectActivitiesConstants.INREVIEW_STATUSES;
      filterCriteria.status = {
        operator: "in",
        value: activityStatuses,
      };
      filterCriteria.isUnderReview = {
        operator: "eq",
        value: true,
      };
    }

    await searchActivities({
      paging: {
        limit: 10,
        beforeCursor: null,
        afterCursor: null,
      },
      sort: [
        {
          key: "latestVersion.updatedAt" as "results.latestVersion.updatedAt",
          direction: "desc",
        },
      ],
      /* eslint-enable */
      filter: { results: filterCriteria },
    })
      .then(async (response) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const projectUuids = response.data?.results?.filter((x) => x.project).map((el) => el.project!.uuid);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const groupUuids = response.data?.results?.filter((x) => x.group).map((el) => el.group!.uuid!);
        await getCurrentUserProjectPermissions({ projectUuids, groupUuids }).then((permissions) => {
          data = {
            resultData: formatActivitiesData(response.data, permissions.data || []),
            paging: {
              startCursor: response.data?.paging?.startCursor || "",
              endCursor: response.data?.paging?.endCursor || "",
              hasNextPage: false,
              hasPreviousPage: false,
              pageSize: paging.pageSize || 10,
              totalCount: response.data?.paging?.total || response.data?.results?.length || 0,
            },
          };
        });
      })
      .finally(() => {
        setIsLoadingActivities(false);
      });
    return data;
  };

  const onRecentActivitiesInProgressDataLoad: DataGridCursorDataLoadEventHandler = async (
    changeProps: CursorChangeProps
  ) => {
    return onRecentActivitiesDataLoad(changeProps, true, false);
  };

  const onRecentActivitiesChangesRequiredDataLoad: DataGridCursorDataLoadEventHandler = async (
    changeProps: CursorChangeProps
  ) => {
    return onRecentActivitiesDataLoad(changeProps, false, true);
  };

  const navigateToProjectInvitations = (): void => {
    navigate(getSettingsProjectAccessRoute(currentUserType));
  };

  return {
    developerHasReceivedProjectInvitations,
    navigateToProjectInvitations,
    statusChartData,
    unitsChartData,
    metricsChartData,
    tableColumnsTopProjects,
    tableColumnsInProgressActivities,
    tableColumnsInReviewActivities,
    isLoading,
    isLoadingProjects,
    isLoadingActivities,
    onTopProjectsDataLoad,
    onRecentActivitiesInProgressDataLoad,
    onRecentActivitiesChangesRequiredDataLoad,
    quickActionsMenuOptionsList,
    showProfileMenu,
    showCreateProjectModal,
    setShowProfileMenu,
    setShowCreateProjectModal,
    setShowVersionConflictModal,
    showVersionConflictModal,
    hasPermission,
    newVersionActivityHistory,
    draftActivityHistoryUuid,
    currentUserType,
    currentUserRole,
  };
};
